import { DocumentSnapshot, Timestamp } from '../firestoreImports';
import { User } from './user'

interface AbuseInfoParameters {
	id?: string
	reservationId: string
	abuserLicense?: string
	spotId: string
	abuseeId: string
	newReservationId?: string
	timestampUpdated: Date
}
export class AbuseInfo {
	id?: string
	reservationId: string
	abuserLicense?: string
	spotId: string
	abuseeId: string
	newReservationId?: string
	timestampUpdated: Date

	//Helper property
	abuseeData?: User

	constructor(params: AbuseInfoParameters) {
		this.id = params.id
		this.reservationId = params.reservationId
		this.abuserLicense = params.abuserLicense
		this.spotId = params.spotId
		this.abuseeId = params.abuseeId
		this.newReservationId = params.newReservationId
		this.timestampUpdated = params.timestampUpdated
	}

    //Creates new key value object to save on firestore
    asData(updateTimestamp: boolean = false): {[k: string]: any} {
        const data: {[k: string]: any} = {
            reservationId:      this.reservationId,
            abuserLicense:      this.abuserLicense,
            spotId:             this.spotId,
            abuseeId:           this.abuseeId
        }
        if (updateTimestamp) {
			data.timestampUpdated = Timestamp.now()
        }
        if (this.newReservationId) {
        	data.newReservationId = this.newReservationId
        }
        return data
    }

	static fromData(doc: DocumentSnapshot): AbuseInfo | null {
		if (!doc.data()) {
            console.warn("Cannot create AbuseInfo from snapshot")
            return null
        }
		const data = doc.data()!
		const timestampUpdated = data.timestampUpdated as Timestamp
		if (!timestampUpdated) {
			console.warn("Cannot create AbuseInfo from snapshot")
			return null
		}

		const spot = new AbuseInfo({
			id: doc.id,
			reservationId: data.reservationId,
			abuserLicense: data.abuserLicense,
			spotId: data.spotId,
			abuseeId: data.abuseeId,
			newReservationId: data.newReservationId,
			timestampUpdated: timestampUpdated?.toDate()
		})
		return spot
	}

	static compare(a: AbuseInfo, b: AbuseInfo) {
		let licenseCompare = 0
		if (a.abuserLicense && b.abuserLicense) {
			licenseCompare = a.abuserLicense.localeCompare(b.abuserLicense)
		}
        if (licenseCompare === 0) {
			return b.timestampUpdated.getTime() - a.timestampUpdated.getTime()
		}
		return licenseCompare
	}

}