export enum ApiErrorCode {
    NoRide = 0,
    NoMoreSpots = 1,
    NoBooking,
    InvalidInput,
    Unknown,
    PastReservation,
	NoCars,
	NoSuchCar,
	DuplicateReservation,
	DomainAlreadyUsed,
	BannedUser,
    InvalidVehicleIdentificators
}

export class ApiError extends Error {
    code?: number

    constructor(message: string, code?: number) {
        super(message)
        this.code = code
    }
};

export interface ValidationResult {
	valid: boolean
	company?: string
	locationIds?: [string]
}

export interface DomainValidationResult {
	valid: boolean
	company?: string
	locationIds?: [string]
    ssoProviderId?: string
    passwordEnabled?: boolean
}


export enum ReservationResultState {
	Confirmed, Pending, Declined
}
export class ReservationResult {
	constructor(
		public state: ReservationResultState,
		public parkingSpotName: string | undefined = undefined,
		public parkingSpotId: string | undefined = undefined,
		public reservationId: string | undefined = undefined
	) {}
}

export class OccupancyResult {
	constructor(
		public remainingSpots: number,
		public confirmedReservations: number,
		public pendingReservations: number
	) {}
}