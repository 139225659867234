import { createTheme } from '@mui/material'
import styles from 'styles/color-palette.scss'

declare module '@mui/material' {
    interface Palette {
        confirmed: string;
        pending: string;
        declined: string;
      }
      interface PaletteOptions {
        confirmed: string;
        pending: string;
        declined: string;
      }
}


const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: '1rem'
      },
    },
    palette: {
        text: {
            primary: styles.textPrimary
        },
        primary: {
          main: styles.colorPrimary
        },
        secondary: {
            main: styles.colorSecondary
        },
        confirmed: styles.colorConfirmed,
        pending: styles.colorPending,
        declined: styles.colorDeclined
    }
})

export default theme