import { DocumentSnapshot, Timestamp } from '../firestoreImports'
import moment from 'moment-timezone'
import { User, UserPriorityLevel } from './user'
import { Car, EngineType } from './car'

export enum ReservationStatus {
	Abused = "Abused",
	Canceled = "Canceled",
	Confirmed = "Confirmed",
	Declined = "Declined",
	Pending = "Pending"
}

const timestampOrNull = (date?: Date) => date ? Timestamp.fromDate(date) : null

export interface ReservationParameters {
	id?: string
	userId: string
	startTime: Date
	endTime: Date
	confirmed?: boolean
	canceled?: boolean
	abused?: boolean
	dateCanceled?: Date
	parkingSpot?: string
	parkingSpotId?: string
	priority?: UserPriorityLevel
	carId?: string
	timestampCreated?: Date
	rideId?: string
}
//Priority:
//	Basic = 0, Higher = 1, Favored = 4, Carpooling = 6, Priority = 7, Visitors = 8, Permanent = 9, Admin = 10, Banned = -5, Restricted = -3
export class Reservation {
	id?: string
	userId: string
	startTime: Date
	endTime: Date
	confirmed?: boolean
	canceled?: boolean
	abused?: boolean
	dateCanceled?: Date
	parkingSpot?: string
	parkingSpotId?: string
	priority?: UserPriorityLevel
	parkingUsage?: any
	user?: User //Helper property
	carId?: string
	timestampCreated?: Date
	date!: Date; // Backward compatibility date, this could be removed eventually
	isLegacyReservation: boolean
	rideId?: string

	constructor(parameters: ReservationParameters) {
		this.id = parameters.id
		this.userId = parameters.userId
		this.userId = parameters.userId
		this.startTime = parameters.startTime
		this.endTime = parameters.endTime
		this.confirmed = parameters.confirmed
		this.parkingSpot = parameters.parkingSpot
		this.parkingSpotId = parameters.parkingSpotId
		this.canceled = parameters.canceled
		this.abused = parameters.abused
		this.dateCanceled = parameters.dateCanceled
		this.priority = parameters.priority
		this.carId = parameters.carId
		this.timestampCreated = parameters.timestampCreated

		this.date = moment(this.startTime).set({h:10,m:0,s:0,ms:0}).toDate() //In from data overridden by original date
		this.isLegacyReservation = false
	}

	static fromData(doc: DocumentSnapshot): Reservation | null {
		if (!doc.data()) {
            console.warn(`Cannot create Reservation ${doc.id} from snapshot`)
            return null
        }
		const data = doc.data()!
		const date = (data.date as Timestamp | undefined)?.toDate()
		let startTime = (data.startTime as Timestamp | undefined)?.toDate()
		let endTime = (data.endTime as Timestamp | undefined)?.toDate()
		if (!date && (!startTime || !endTime)) {
			console.warn(`Cannot create Reservation ${doc.id} from snapshot`)
			return null
		}
		let isLegacyReservation = false
		//This will only work (obviously) for central europe time, we should not have to worry as all the garage frontends use startTime, endTime now. This is mainly for reservations from carpool.
		if (!startTime || !endTime) {
			startTime = moment.tz(date!, "Europe/Prague").startOf('d').toDate()
			endTime = moment.tz(date!, "Europe/Prague").endOf('d').toDate()
			isLegacyReservation = true
		}

		const dateCanceled = data.dateCanceled as Timestamp | undefined
		const timestampCreated = data.timestampCreated as Timestamp | undefined
		const reservation = new Reservation({
			       userId: data.userId,
	 	        startTime: startTime,
	 	          endTime: endTime,
			    confirmed: data.confirmed,
			  parkingSpot: data.parkingSpot,
			parkingSpotId: data.parkingSpotId,
			     canceled: data.canceled,
			       abused: data.abused,
		     dateCanceled: dateCanceled?.toDate(),
			           id: doc.id,
			     priority: data.priority,
			        carId: data.carId,
         timestampCreated: timestampCreated?.toDate()
		})
		if (date) {
			reservation.date = date
		}
		reservation.rideId = data.rideId
		reservation.isLegacyReservation = isLegacyReservation
		return reservation
	}

	car = (carsArg?: Car[]): Car | undefined => {
		const cars = carsArg ? carsArg : this.user?.cars
		if (!cars) {return undefined}
		const car = cars.find(c => c.id === this.carId)
		if (car) {
			return car
		}
		return cars[0]
	}

	///If reservation has carId, check for cars if the one is electric, otherwise true if any car is electric
	isElectric = (carsArg?: Car[]): boolean => {
		const cars = carsArg ? carsArg : this.user?.cars
		if (!cars) {return false}
		const car = cars.find(c => c.id === this.carId)
		if (car) {
			return car.isElectric
		}
		return cars.some(c => c.isElectric)
	}

	isNaturalGas = (): boolean => {
		const cars = this.user?.cars
		const car = cars?.find(c => c.id === this.carId) ?? cars?.[0]
		return car?.engineType === EngineType.NaturalGas
	}

	//Backwards compatibility helper for date param
	timeZoneAwareDate = (timezone: string): Date => {
		return moment.tz(this.startTime, timezone).set({h:10,m:0,s:0,ms:0}).toDate()
	}

	status = () => {
		if (this.abused) {
			return ReservationStatus.Abused
		}
		if (this.canceled) {
			return ReservationStatus.Canceled
		}
		if (this.confirmed) {
			return ReservationStatus.Confirmed
		}
		if (this.confirmed ===  false) {
			return ReservationStatus.Declined
		}
		return ReservationStatus.Pending
	}

	toDict(timezone: string): any {
		return {
            date: Timestamp.fromDate(this.timeZoneAwareDate(timezone)),
            startTime: Timestamp.fromDate(this.startTime),
            endTime: Timestamp.fromDate(this.endTime),
            userId: this.userId,
            priority: this.priority ?? 0,
            parkingSpot: this.parkingSpot ?? null,
            parkingSpotId: this.parkingSpotId ?? null,
			confirmed: this.confirmed ?? null,
            carId: this.carId ?? null,
            timestampCreated: timestampOrNull(this.timestampCreated) ?? Timestamp.now()
        }
	}
}

interface PermanentReservationParameters {
	underlyingReservation: Reservation
	validFrom?: Date
	validTo?: Date
}

export class PermanentReservation {
	underlyingReservation: Reservation
	validFrom?: Date
	validTo?: Date

	constructor(parameters: PermanentReservationParameters) {
		this.underlyingReservation = parameters.underlyingReservation
		this.validFrom = parameters.validFrom
		this.validTo = parameters.validTo
	}

	static fromData(doc: DocumentSnapshot): PermanentReservation | null {
		if (!doc.data()) {
            console.warn(`Cannot create PermanentReservation ${doc.id} from snapshot`)
            return null
        }
		const reservation = Reservation.fromData(doc)
		if (!reservation) {
			return null
		}
		const data = doc.data()!
		const validFrom = (data.validFrom as Timestamp | undefined)?.toDate() ?? reservation.timestampCreated
		const validTo = (data.validTo as Timestamp | undefined)?.toDate()

		return new PermanentReservation({
			underlyingReservation: reservation,
			validFrom: validFrom,
			validTo: validTo
		})
	}

	toDict(timezone: string): any {
		const dict = this.underlyingReservation.toDict(timezone)
		dict.validFrom = timestampOrNull(this.validFrom)
		dict.validTo = timestampOrNull(this.validTo)
		return dict
	}

	isValid = () => {
		if (this.underlyingReservation.canceled) {
			return false
		}
		if (this.validFrom && this.validFrom.getTime() > Date.now()) {
			return false
		}
		if (this.validTo && this.validTo.getTime() < Date.now()) {
			return false
		}
		return true
	}
}