import React from "react";
import ReactDOM from "react-dom/client";
//import reportWebVitals from "./reportWebVitals";

import App from './components/Main/app.tsx';
import Firebase, { FirebaseContext } from './components/Firebase';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
	<FirebaseContext.Provider value={new Firebase()}>
		<App />
	</FirebaseContext.Provider>
  </React.StrictMode>
);

//reportWebVitals();