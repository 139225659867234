import TagManager from "react-gtm-module"
import { gtmConfig } from "constants/config.js"

import * as CookieConsent from "vanilla-cookieconsent"
import "vanilla-cookieconsent/dist/cookieconsent.css"
import "styles/cookies.css"
import { useEffect } from "react"

import strings_en from "shared-components/internationalization/cookies_translation_en.json"
import strings_cz from "shared-components/internationalization/cookies_translation_cz.json"

interface ICookieBannerProps {
    selectedLanguageId: string
}

export default function CookieBanner(props: ICookieBannerProps) {

    useEffect(() => {
        CookieConsent.setLanguage(props.selectedLanguageId)
    }, [props.selectedLanguageId])

    CookieConsent.run({
        cookie: {
            name: "yedem_garage_cookie",
            expiresAfterDays: 365
        },

        onConsent: function () {
            if (CookieConsent.acceptedCategory("analytics")) {
                //Google tag manager
                TagManager.initialize(gtmConfig)
            }
        },

        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
        guiOptions: {
            consentModal: {
                layout: "box",
                position: "bottom right",
                equalWeightButtons: true,
                flipButtons: false
            },
            preferencesModal: {
                layout: "box",
                equalWeightButtons: true,
                flipButtons: false
            }
        },

        categories: {
            necessary: {
                enabled: true, // this category is enabled by default
                readOnly: true // this category cannot be disabled
            },
            analytics: {
                autoClear: {
                    cookies: [
                        {
                            name: /^_ga/ // regex: match all cookies starting with '_ga'
                        },
                        {
                            name: "_gid" // string: exact cookie name
                        }
                    ]
                }
            }
        },

        language: {
            default: "en",
            translations: {
                en: strings_en,
                cs: strings_cz
            }
        }
    })
    return null
}
